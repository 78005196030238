const isValidJSON = (value: string) => {
    try {
        const json = JSON.parse(value);
        return { isValid: true, json };
    } catch (error) {
        return { isValid: false };
    }
};

export default isValidJSON;
