import TKFileUploadArea from '@tk/controls/tk.file.upload.area';

interface ResponseData {
    path: string;
    name: string;
    fileId: string;
    success: boolean;
    type: string;
    mode: string;
}
export default class KWFileUploadArea extends TKFileUploadArea {
    constructor() {
        super();
    }

    async getResponseData(responseText: string, response: unknown) {
        if (response instanceof XMLHttpRequest && response.status === 200) {
            const response = await this.moveFile();
            const {
                path,
                success,
                type,
                mode,
            } = response.dataAsJson as ResponseData;
            if (!success) {
                const error = { message: 'FAILED!' };
                this.uppy?.emit('error', error);
                this.uppy?.emit('upload-cancel', error);
            } else {
                if (this.isWithinIFrame) {
                    window.parent.postMessage({
                        mceAction: 'close',
                        content: path,
                        size: {
                            width: 0,
                            height: 0,
                        },
                    }, '*');
                }

                if (type === 'dialog') {
                    const dialog = this.closest('dialog');
                    if (dialog) {
                        const button = dialog.querySelector<HTMLButtonElement>(`[data-tk-dialog-${mode}]`);
                        if (button) {
                            button.disabled = false;
                            button.click();
                        }
                    }
                }

                return {
                    url: path,
                    type,
                    mode,
                };
            }
        }
    }
}
