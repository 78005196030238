import TKBasketAction, { BasketData } from '@tk/utilities/tk.basket.action';

const eventMap = {
    added: 'opc_cart_add',
    deleted: 'opc_cart_delete',
    changed: 'opc_cart_update',
};

export default class KWBasketAction extends TKBasketAction {
    constructor(component: HTMLElement) {
        super(component);
    }

    updateBasket(data: BasketData) {
        super.updateBasket(data);

        const {
            action, currency, itemAmount, salDocItemBoId, quantity,
        } = data;

        // eslint-disable-next-line no-underscore-dangle
        const mtm = window._mtm as unknown[];
        mtm.push({
            event: eventMap[action as keyof typeof eventMap],
            location: window.location.href,
            currency,
            value: itemAmount,
            items: [{
                sku: salDocItemBoId,
                name: '',
                category: [''],
                price: itemAmount,
                quantity,
            }],
        });
    }
}
