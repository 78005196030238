import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKFormValidator from '@tk/controls/tk.form.validator';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';

export default class KWTourSchedule extends TKCustomElementFactory {
    form?: HTMLFormElement;
    url?: string;
    formValidator?: TKFormValidator;

    constructor() {
        super();

        this.form = document.querySelector('[data-tk-tour-form]') || undefined;
        const closestValidator = this.form?.closest('tk-form-validator');

        if (this.form && closestValidator) {
            this.formValidator = closestValidator as TKFormValidator;
        }

        this.url = this.getAttribute('data-tk-url') || undefined;
    }

    connectedCallback(): void {
        if (!this.form || !this.url) return;

        this.pushListener({
            event: 'submit',
            element: this.form,
            action: this.getTourDetails.bind(this),
        });
    }

    getTourDetails(e:SubmitEvent) {
        e.preventDefault();

        if (this.formValidator) {
            this.formValidator.isFormValid().then((isValid) => {
                if (!isValid) return; // Proceed only if the form is valid
                this.fetchContent();
            });
        } else {
            this.fetchContent();
        }
    }

    fetchContent() {
        const formData = new FormData(this.form);

        const data:Record<string, string> = {
            zip: formData.get('tour-zip') as string,
            city: formData.get('tour-city') as string,
        };

        // fetch content
        fetchRequest({
            requestURL: this.url!,
            resolveHandler: this.showTourDetails.bind(this),
            payload: data,
        });
    }

    showTourDetails(response: TKResponse) {
        // show content
        this.replaceChildren(render(response.dataAsHtml, true));

        this.form!.reset();
    }
}
