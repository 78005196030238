import TKDialog from '@tk/components/tk.dialog';
import render from '@tk/utilities/tk.render';
import { fetchRequest } from '@tk/utilities/tk.fetch';

interface RenderedDialog {
    className?: string;
    closeClassName?: string;
    innerClassName?: string;
    content?: string;
    isConfirm?: boolean;
    confirmURL?: string;
    confirmTitle?: string;
    confirmDescription?: string;
    confirmCancelIcon?: string;
    confirmCancelLabel?: string;
    confirmSubmitIcon?: string;
    confirmSubmitLabel?: string;
}

enum DialogActions {
    JUMP_FORWARD = 'jump_forward',
    JUMP_BACKWARD = 'jump_backward',
    JUMP_TO = 'jump_to',
    RESET_FORM = 'reset_form',
    REFRESH = 'refresh',
}

export default class KWDialog extends TKDialog {
    tag: keyof HTMLElementTagNameMap;

    constructor() {
        super();
        this.tag = this.getAttribute('data-tk-tag') as keyof HTMLElementTagNameMap || 'dialog';
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    createDialog(url: string): HTMLDialogElement {
        const dialog = KWDialog.renderDialog({
            className: `${this.dialogClassName} ${this.shouldMaxWidth ? this.maxWidthClassName : ''}`,
            closeClassName: this.dialogCloseClassName,
            innerClassName: this.dialogInnerClassName,
            tag: this.tag,
            isConfirm: this.isConfirm,
            confirmURL: url,
            confirmTitle: this.confirmTitle,
            confirmDescription: this.confirmDescription,
            confirmCancelIcon: this.confirmCancelIcon,
            confirmCancelLabel: this.confirmCancelLabel,
            confirmSubmitIcon: this.confirmSubmitIcon,
            confirmSubmitLabel: this.confirmSubmitLabel,
        });
        const onClosed = this.closedDialog.bind(this);
        this.pushListener({ event: 'close', element: dialog, action: onClosed });
        this.currentDialog = this.dialogs.push({ url, dialog }) - 1;
        return dialog;
    }

    static renderDialog<T extends HTMLElement = HTMLDialogElement>(
        options: RenderedDialog & { tag: keyof HTMLElementTagNameMap },
    ): T {
        const {
            tag,
            className = 'tk-dialog',
            closeClassName = 'tk-button tk-button--tertiary tk-button--only-icon tk-dialog__close',
            innerClassName = 'tk-dialog__inner',
            content,
            isConfirm = false,
            confirmURL,
            confirmTitle,
            confirmDescription,
            confirmCancelIcon,
            confirmCancelLabel,
            confirmSubmitIcon,
            confirmSubmitLabel,
        } = options;

        const dialog = render<T>(`
            ${isConfirm ? `
                    <${tag} class="${className}">
                        <button
                            type="button"
                            class="${closeClassName}"
                            data-tk-dialog-close
                        ></button>
                        <div class="${innerClassName}" data-tk-dialog-content>
                            <form action="${confirmURL}" method="post">
                                <div class="tk-dialog__header">
                                    <h3>${confirmTitle}</h3>
                                </div>
                                <div class="tk-dialog__body">
                                    <p>${confirmDescription}</p>
                                </div>
                                <div class="flex flex--gap-x-2 flex--justify-end tk-dialog__footer">
                                    <button type="button" class="tk-button tk-button--secondary" data-tk-dialog-close>
                                        <span class="tk-button__icon">
                                            <i class="tk-icon-${confirmCancelIcon}"></i>
                                        </span>
                                        <span class="tk-button__label">${confirmCancelLabel}</span>
                                    </button>
                                    <button type="submit" class="tk-button tk-button--primary">
                                        <span class="tk-button__icon">
                                            <i class="tk-icon-${confirmSubmitIcon}"></i>
                                        </span>
                                        <span class="tk-button__label">${confirmSubmitLabel}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </${tag}>
                ` : `
                    <${tag} class="${className}">
                        <button
                            type="button"
                            class="${closeClassName}"
                            data-tk-dialog-close
                        ></button>
                        <div class="${innerClassName}" data-tk-dialog-content>
                            ${content}
                        </div>
                    </${tag}>
                `}
        `);
        document.body.insertAdjacentElement('beforeend', dialog);
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-close]',
            () => TKDialog.close(dialog),
        );
        return dialog;
    }

    getContent(url: string): void {
        if (this.isConfirm) {
            this.closeCurrentDialog();
            const dialog = this.createDialog(url);
            KWDialog.open(dialog, this.isPopup);
        } else {
            fetchRequest({
                requestURL: url,
                resolveHandler: this.handleResponseSuccess.bind(this, url),
            });
        }
    }

    handleResponseSuccess(url: string, response: TKResponse): void {
        if (!response || !response.success) return;
        this.closeCurrentDialog();
        const dialog = this.createDialog(url);
        const content = dialog.querySelector('[data-tk-dialog-content]');
        if (!content) return;
        content.innerHTML = response.dataAsHtml;
        KWDialog.open(dialog, this.isPopup);
        this.hookAfterShowModal(dialog);
        const form = content.querySelector('form');
        form && this.registerFormListener(form);
    }

    handleUrlAndDialogs(url: string) {
        const existsURL = this.dialogs.some((dialogItem) => dialogItem.url === url);
        if (existsURL) {
            const nextDialog = this.getDialogByURL(url);
            if (!nextDialog) return;
            const waitForContent = this.forceReloadContent;
            if (this.forceReloadContent) {
                this.renewDialogContent(nextDialog, url);
            }
            if (waitForContent) return;
            KWDialog.open(nextDialog);
        } else {
            try {
                const payload = JSON.parse(this.formDataString || '{}');
                Object.keys(payload).length > 0 ? this.getContentWithData(url, payload) : this.getContent(url);
            } catch (error) {
                throw new Error(`Dialog: ${error}`);
            }
        }
    }

    static closeDialogIfOutside(element: HTMLDialogElement, event: MouseEvent) {
        /**
         * Checking the coordinates of the client for the value 0 and the
         * HTMLSelectElement or HTMLOptionElement element ensures that the
         * dialogue does not close in Mozilla. This is a bug in Firefox
         * that has not yet been fixed.
         */
        if (
            event.clientX === 0
            && event.clientY === 0
            && (event.target instanceof HTMLSelectElement || event.target instanceof HTMLOptionElement)
        ) return;
        const dialogDimensions = element.getBoundingClientRect();
        if (
            event.clientX < dialogDimensions.left
            || event.clientX > dialogDimensions.right
            || event.clientY < dialogDimensions.top
            || event.clientY > dialogDimensions.bottom
        ) {
            KWDialog.close(element);
        }
    }

    hookAfterShowModal(dialog: HTMLDialogElement): void {
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-close]',
            () => KWDialog.close(dialog),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-close-and-reset]',
            (button) => this.handleActions(DialogActions.RESET_FORM, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-jump-forward]',
            (button) => this.handleActions(DialogActions.JUMP_FORWARD, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-jump-backward]',
            (button) => this.handleActions(DialogActions.JUMP_BACKWARD, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-jump-to]',
            (button) => this.handleActions(DialogActions.JUMP_TO, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-refresh]',
            (button) => this.handleActions(DialogActions.REFRESH, button),
        );
    }

    handleActions(action: DialogActions, button: HTMLButtonElement) {
        const handler: Record<DialogActions, () => void> = {
            jump_forward: () => this.forward(button),
            jump_backward: () => this.backward(),
            jump_to: () => this.jumpTo(button),
            reset_form: () => this.resetForm(button),
            refresh: () => this.refresh(button),
        };
        handler[action]();
    }

    closeCurrentDialog() {
        const dialogItem = this.dialogs.at(this.currentDialog);
        if (!dialogItem) return;
        KWDialog.close(dialogItem.dialog);
    }

    closeDialog(event: PointerEvent): void {
        const dialogItem = this.dialogs.at(this.currentDialog);
        // Used to check if the event isn't triggered by the enter key
        const isRealClickEvent = event.pointerId !== -1;

        if (!dialogItem || !isRealClickEvent) return;
        const { dialog } = dialogItem;
        if (this.closeOnBackdropClick) {
            KWDialog.closeDialogIfOutside(dialog, event);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    refresh(button: HTMLButtonElement) {
        // Check if already exists
        const url = button.getAttribute('data-tk-url');
        if (!url) throw new Error('Dialog: URL is missing!');
        window.open(url, '_self');
    }
}
