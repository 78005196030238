import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKArticleList, { ResponseItem } from '@tk/components/tk.article.list';
import KWStock from '@/components/kw.stock';
import KWPrice from '@/components/kw.price';

interface KWResponseItem extends ResponseItem {
    articleId: string;
}

interface KWResponseData {
    items?: KWResponseItem[];
}

export default class KWArticleList extends TKArticleList {
    chunkSize: number;

    constructor() {
        super();
        this.chunkSize = Number(this.getAttribute('data-tk-chunk-size')) || 20;
    }

    handlePrice() {
        const elements = this.querySelectorAll('tk-price');
        if (elements.length !== this.prices.length) return;

        const url = this.getAttribute('data-tk-async-url') || setAsyncUrl(true);
        const data = {
            crypt: '',
            articles: '',
        };
        const crypt = elements.item(0).getAttribute('data-tk-async-crypt');
        crypt && (data.crypt = crypt);

        const articleChunks = this.splitIntoChunks(KWPrice.getItemsAsArray(this.prices));

        articleChunks.forEach((chunk) => {
            data.articles = chunk;
            fetchRequest({
                requestURL: url,
                resolveHandler: this.refreshPriceData.bind(this),
                payload: data,
            });
        });
    }

    handleStock() {
        const elements = this.querySelectorAll('[data-tk-stock-wrapper]');
        if (elements.length !== this.stocks.length) return;

        const url = this.getAttribute('data-tk-async-url') || setAsyncUrl(true);
        const data = {
            crypt: '',
            articles: '',
        };
        const crypt = elements.item(0).getAttribute('data-tk-async-crypt');
        crypt && (data.crypt = crypt);

        const stockData = KWStock.getItemsAsArray(this.stocks);
        const articleChunks = this.splitIntoChunks(stockData.articles);

        articleChunks.forEach((chunk) => {
            data.articles = chunk;

            fetchRequest({
                requestURL: url,
                resolveHandler: this.refreshStockData.bind(this),
                payload: data,
            });
        });
    }

    refreshPriceData(response: TKResponse<KWResponseData>) {
        if (!response || !response.success) return;
        const responseItems = response.dataAsJson.items;
        responseItems?.forEach((responseItem) => {
            const { articleId } = responseItem;
            const item = this.prices.find((article) => article.articleId === articleId);
            const element = item?.element;
            element && element.refreshPrice(item, responseItem);
        });
    }

    refreshStockData(response: TKResponse<KWResponseData>) {
        if (!response || !response.success) return;
        const responseItems = response.dataAsJson.items;
        responseItems?.forEach((responseItem) => {
            const { articleId } = responseItem;
            const items = this.stocks.filter((article) => article.articleId === articleId);
            items.forEach((item) => {
                const element = item?.element;
                element && element.refreshStock(item, responseItem);
            });
        });
    }

    splitIntoChunks(array: string[]) {
        const result = [];
        for (let i = 0; i < array.length; i += this.chunkSize) {
            result.push(array.slice(i, i + this.chunkSize).join(','));
        }
        return result;
    }
}
