/* eslint-disable class-methods-use-this */
import TKFavoriteButton from '@tk/components/tk.favorite.button';

enum Actions {
    ADD = 'add',
    ADDED = 'added',
    DELETED = 'deleted',
    MOVED = 'moved',
    MOVE = 'move',
}

interface FavoriteData {
    action: Actions;
    additionalAttributes: string;
    artBoId: string;
    artNo: string;
    favoriteCount: number;
    isGuest: boolean;
    isMulti: boolean;
    maxType: string;
    message: string;
    onList: string;
    salDocBoIdSource: string;
    salDocBoIdTarget: string;
    salDocName: string;
    success: boolean;
    titleAdd: string;
    titleDelete: string;
    tooHigh: boolean;
}

const eventMap = {
    added: 'opc_wishlist_add',
    deleted: 'opc_wishlist_delete',
};

export default class KWFavoriteButton extends TKFavoriteButton {
    constructor() {
        super();
    }

    addedItem(response: TKResponse<FavoriteData>) {
        super.addedItem(response);
        this.pushTrackItem(response.dataAsJson);
    }

    deleteArticle(id: string, response: TKResponse<FavoriteData>) {
        super.deleteArticle(id, response);
        this.pushTrackItem(response.dataAsJson);
    }

    pushTrackItem(data: FavoriteData) {
        const {
            action,
            artNo,
        } = data;

        // eslint-disable-next-line no-underscore-dangle
        const mtm = window._mtm as unknown[];
        mtm.push({
            event: eventMap[action as keyof typeof eventMap],
            location: window.location.href,
            category: 'Wishlist',
            action,
            name: artNo,
            value: '1',
        });
    }
}
