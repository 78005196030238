import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKDialog from '@tk/components/tk.dialog';
import TKFormValidator from '@tk/controls/tk.form.validator';
import { NotificationType, sendNotification, sendNotificationByItem } from '@tk/utilities/tk.notification';
import KWFavoriteInfo from './tk.favorite.info';

enum Actions {
    ADD = 'add',
    ADDED = 'added',
    DELETED = 'deleted',
    MOVED = 'moved',
    MOVE = 'move',
}

interface FavoriteData {
    action: Actions;
    additionalAttributes: string;
    artBoId: string;
    artNo: string;
    favoriteCount: number;
    isGuest: boolean;
    isMulti: boolean;
    maxType: string;
    message: string;
    onList: string;
    salDocBoIdSource: string;
    salDocBoIdTarget: string;
    salDocName: string;
    success: boolean;
    titleAdd: string;
    titleDelete: string;
    tooHigh: boolean;
}

export default class TKFavoriteButton extends TKCustomElementFactory {
    button?: HTMLButtonElement;
    artBoId: string;
    type: string;
    artNumber: string;
    asyncCountId: string;
    multiFavoritesAction: string;
    onList: string;
    saldocBoIdTarget: string;
    saldocBoIdSource: string;
    maxList: number;
    maxPosition: number;
    activeClassName: string;
    listName: string;
    selectedOption: number;
    // eslint-disable-next-line no-use-before-define
    addButton?: TKFavoriteButton;
    isGuestUser: boolean;

    constructor() {
        super();

        this.button = this.querySelector('button') || undefined;
        this.artBoId = this.getAttribute('data-tk-art-bo-id') || '';
        this.type = this.getAttribute('data-tk-type') || '';
        this.artNumber = this.getAttribute('data-tk-art-number') || '';
        this.asyncCountId = this.getAttribute('data-tk-async-count-id') || '';
        this.multiFavoritesAction = this.getAttribute('data-tk-multi-favorites-action') || '';
        this.onList = this.getAttribute('data-tk-on-list') || '';
        this.saldocBoIdTarget = this.getAttribute('data-tk-saldoc-bo-id-target') || '';
        this.saldocBoIdSource = this.getAttribute('data-tk-saldoc-bo-id-source') || '';
        this.maxList = Number(this.getAttribute('data-tk-max-list'));
        this.maxPosition = Number(this.getAttribute('data-tk-max-position'));
        this.activeClassName = this.getAttribute('data-tk-active-class-name') || 'tk-button--selected';
        this.listName = this.getAttribute('data-tk-list-name') || '';
        this.selectedOption = Number(this.getAttribute('data-tk-selected-option')) || 1;
        this.isGuestUser = this.hasAttribute('data-tk-guest-user');
    }

    connectedCallback(): void {
        if (!this.button) throw new Error('Favorite: Button is missing!');
        this.registerClickListener();
    }

    registerClickListener() {
        const onClick = this.send.bind(this);
        this.pushListener({ event: 'click', element: this.button!, action: onClick });
    }

    async send(event: Event) {
        // If user is not logged in and article is already on list return
        if (this.getAttribute('data-tk-multi-favorites-action') === Actions.ADDED && this.isGuestUser) return;

        const formValidator = this.closest<TKFormValidator>('tk-form-validator');
        const formElement = formValidator?.form;
        if (formElement) {
            const formData = new FormData(formElement);
            const optionElements = formElement.querySelectorAll<HTMLInputElement>('input[name="favorite-option"]');
            const optionElementList = Array.from(optionElements);
            const selectedOptionIndex = optionElementList.findIndex((element) => element.checked);

            // if the selected option is `existing`, then take the `saldocBoIdTarget`
            if (selectedOptionIndex === this.selectedOption) {
                const selectElement = formElement.querySelector('select');
                const selectedOption = selectElement?.selectedOptions.item(0);
                this.saldocBoIdTarget = selectedOption?.getAttribute('data-tk-saldoc-bo-id-target') || '';
            }
            if (this.listName === '') {
                const { length } = formData.getAll('field-name');
                this.listName = length > 1
                    ? formData.getAll('field-name')?.at(selectedOptionIndex)?.toString() || ''
                    : formData.get('field-name')?.toString() || '';
            } else {
                this.listName = formData.get('field-name')?.toString() || '';
            }
            if (
                this.multiFavoritesAction === Actions.ADDED
                || this.multiFavoritesAction === Actions.MOVED
            ) this.checkMaximum(formElement, selectedOptionIndex);
        }

        const isFormValid = await formValidator?.isFormValid();
        if (formValidator && !isFormValid) return;

        const data: Record<string, string | undefined> = {
            Type: 'favorite',
            ArtFavorite_ArtNo: this.artNumber,
            ArtFavorite_ArtBoId: this.artBoId,
            ArtFavorite_ListName: this.listName,
            ArtFavorite_MultiAction: this.multiFavoritesAction,
            ArtFavorite_OnList: this.onList,
            ArtFavorite_SalDocBoIdSource: this.saldocBoIdSource,
            ArtFavorite_SalDocBoIdTarget: this.saldocBoIdTarget,
            maxLists: String(this.maxList),
            maxPositions: String(this.maxPosition),
        };
        Object.keys(this.dataset)
            .filter((key) => key.startsWith('opSaldocitem.'))
            .forEach((key) => {
                data[key.substring(2).toLowerCase()] = this.dataset[key];
            });
        const url = setAsyncUrl();
        fetchRequest({
            requestURL: url,
            resolveHandler: this.refreshContent.bind(this, event),
            payload: data,
            showMessages: false,
        });
    }

    refreshContent(event: Event, response: TKResponse<FavoriteData>) {
        if (!response) return;
        if (
            response.success
            && response.messages?.length === 0
            && (
                response.dataAsJson.action === Actions.ADD
                || response.dataAsJson.action === Actions.MOVE
            )
        ) {
            if (
                response.dataAsJson.isGuest
                && this.listName !== ''
            ) {
                const html = document.createElement('div');
                html.innerHTML = response.dataAsJson.message;
                const favoriteButton = html.querySelector('[data-tk-saldoc-bo-id-target]');
                const saldocBoIdTarget = favoriteButton?.getAttribute('data-tk-saldoc-bo-id-target');
                const saldocBoId = favoriteButton?.getAttribute('data-tk-saldoc-bo-id');
                saldocBoIdTarget && (this.saldocBoIdTarget = saldocBoIdTarget);
                this.multiFavoritesAction = Actions.ADDED;
                this.setAttribute('data-tk-on-list', `#${saldocBoId}#`);
                this.send(event);
            } else {
                const { message } = response.dataAsJson;
                const dialog = TKDialog.renderDialog({
                    content: message,
                    className: 'tk-dialog tk-dialog--max-width',
                    tag: 'dialog',
                });
                const favoriteButton = dialog.querySelector<TKFavoriteButton>('tk-favorite-button');
                if (favoriteButton) {
                    favoriteButton.addButton = this;
                }
                dialog.showModal();
                this.pushListener({ event: 'close', element: dialog, action: () => dialog.remove() });
            }
        } else {
            const dialog = this.closest('dialog');
            const { action, artBoId, favoriteCount } = response.dataAsJson;

            action !== Actions.ADDED && dialog?.close();

            const actionHandler: Record<Actions, () => void> = {
                added: () => this.addedItem(response),
                deleted: () => this.deleteArticle(artBoId, response),
                moved: () => this.deleteArticle(artBoId, response),
                [Actions.ADD](): void {
                    throw new Error('Function not implemented.');
                },
                [Actions.MOVE](): void {
                    throw new Error('Function not implemented.');
                },
            };
            actionHandler[action]();
            if (response.dataAsJson.isGuest) return;
            TKFavoriteButton.syncInfo(favoriteCount);
        }
    }

    addedItem(response: TKResponse<FavoriteData>) {
        const { messages } = response;
        const dialog = this.closest('dialog');
        // check for empty value
        if (!response.dataAsJson.salDocName) return;
        dialog?.close();
        messages?.forEach((message) => {
            sendNotificationByItem(message);
        });
        if (this.addButton) {
            this.addButton.setAttribute('data-tk-on-list', response.dataAsJson.onList);
            this.addButton.button!.classList.add(this.activeClassName);
            this.addButton.onList = response.dataAsJson.onList;
        }
        this.button!.setAttribute('data-tk-on-list', response.dataAsJson.onList);
        this.button!.classList.add(this.activeClassName);
        this.onList = response.dataAsJson.onList;
        this.setAttribute('data-tk-multi-favorites-action', 'added');
        TKFavoriteButton.updateItemsCount();
    }

    deleteArticle(id: string, response: TKResponse<FavoriteData>) {
        const article = document.querySelector(`[data-tk-article-id="${id}"]`);
        if (!article) return;
        article.remove();
        TKFavoriteButton.syncCounter();

        const { messages } = response;
        messages?.forEach((message) => {
            sendNotificationByItem(message);
        });

        const contextMenu = this.closest('[data-tk-context-items]');
        if (!contextMenu) return;
        contextMenu.remove();
    }

    checkMaximum(formElement: HTMLFormElement, selectedOptionIndex: number) {
        // if the selected option is `existing`, check positions
        const selectElement = formElement.querySelector('select');
        const selectedOption = selectElement?.selectedOptions.item(0);
        const countPositions = Number(selectedOption?.getAttribute('data-tk-saldoc-count-positions'));
        if (selectedOptionIndex === 0 && countPositions > this.maxPosition) {
            sendNotification(NotificationType.ERROR, 'Maximum erreicht an Positionen.');
            throw new Error('Rechead maximum of positions in this list');
        } else if ((selectElement?.options.length || 0) > this.maxList) {
            sendNotification(NotificationType.ERROR, 'Maximum erreicht an Listen.');
            throw new Error('Rechead maximum of lists');
        }
    }

    static syncCounter() {
        const counterElement = document.querySelector('[data-tk-favorite-counter]');
        const listElement = document.querySelector('[data-tk-article-list-grid]');
        if (!counterElement || !listElement) return;
        counterElement.textContent = String(listElement.childElementCount);
    }

    static syncInfo(quantity: number) {
        const infoElement = document.querySelector('tk-favorite-info');
        if (!infoElement) return;
        infoElement.setAttribute('data-tk-quantity', String(quantity));
    }

    static updateItemsCount() {
        // TODO: After the next WAS update, the item count should be included in the response when not logged in. Until then workaround with + 1
        const favoriteInfoElement = document.querySelector<KWFavoriteInfo>('tk-favorite-info');
        if (!favoriteInfoElement) return;

        const previousItemscount = favoriteInfoElement.getAttribute('data-tk-quantity')
            ? parseInt(favoriteInfoElement.getAttribute('data-tk-quantity')!, 10) : 0;
        favoriteInfoElement.setAttribute('data-tk-quantity', (previousItemscount + 1).toString());
    }
}
