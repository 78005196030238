import { PriceData, ResponseItem } from '@tk/components/tk.article.list';
import TKPrice from '@tk/components/tk.price';
import isBase64 from '@tk/utilities/tk.base64';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';

const QUANTITY_ATTR = 'data-quantity';
const DIMENSION_VARIANT_ATTR = 'data-tk-dimension-variant';
const READY_TO_RUN_ATTR = 'data-tk-ready-to-run';

interface DiscountResponseItem extends ResponseItem {
    basicPrice: number;
    servicePrice: number;
}

export default class KWPrice extends TKPrice {
    quantity?: number;
    dimensionVariant?: number;
    discountWrapper?: HTMLElement;
    discountElement?: HTMLElement;
    discountAction?: HTMLElement;
    readyToRun?: boolean;
    articleWrapper?: HTMLElement;
    dimensionVariantField?: HTMLInputElement;
    asyncURLEncrypt: string | null;

    static observedAttributes = [
        QUANTITY_ATTR,
        DIMENSION_VARIANT_ATTR,
        READY_TO_RUN_ATTR,
    ];

    constructor() {
        super();

        this.quantity = Number(this.getAttribute(QUANTITY_ATTR)) || undefined;
        this.dimensionVariant = Number(this.getAttribute(DIMENSION_VARIANT_ATTR)) || undefined;
        this.discountWrapper = document.querySelector('[data-tk-discount-wrapper]') || undefined;
        this.discountElement = this.discountWrapper?.querySelector('[data-tk-discount-value]') || undefined;
        this.discountAction = this.discountWrapper?.querySelector('[data-tk-discount-action]') || undefined;
        this.readyToRun = this.getAttribute(READY_TO_RUN_ATTR) === 'true';
        this.articleWrapper = this.closest('[data-article-wrapper]') || undefined;
        this.asyncURLEncrypt = this.getAttribute('data-tk-async-crypt');
        this.dimensionVariantField = (
            this.articleWrapper?.querySelector('input[name="dimension-variants"]') || undefined
        );
    }

    connectedCallback(): void {
        if (this.hasAttribute(READY_TO_RUN_ATTR) && !this.readyToRun) return;
        this.initialize();
    }

    initialize() {
        this.dimensionVariantField && (
            this.dimensionVariant = Number(this.dimensionVariantField.value)
        );

        super.connectedCallback();
        this.registerChangeListener();
    }

    registerChangeListener() {
        if (!this.dimensionVariantField) return;
        const onChange = this.switchVariant.bind(this);
        this.pushListener({
            event: 'change',
            element: this.dimensionVariantField,
            action: onChange,
        });
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === QUANTITY_ATTR) {
            const quantity = Number(newValue);
            this.quantity = quantity;
            this.handlePrice();
        } else if (name === DIMENSION_VARIANT_ATTR) {
            const dimensionVariant = Number(newValue);
            this.dimensionVariant = dimensionVariant;
            this.handlePrice();
        } else if (name === READY_TO_RUN_ATTR && newValue === 'true') {
            this.initialize();
        }
    }

    switchVariant(event: Event) {
        const target = event.target as HTMLInputElement;
        const { value } = target;
        this.setAttribute(DIMENSION_VARIANT_ATTR, value);
    }

    handlePrice() {
        if (!this.item) return;
        const items = TKPrice.getItemsAsEntrypoint([this.item]);

        const data = {
            type: 'price',
            ...items,
        } as Record<string, string>;

        this.quantity && (data.quantity = String(this.quantity));
        this.dimensionVariant && (data.dimensionVariant = String(this.dimensionVariant));
        this.asyncURLEncrypt && (data.crypt = this.asyncURLEncrypt);

        fetchRequest({
            requestURL: this.asyncURL,
            resolveHandler: this.handleResponse.bind(this),
            payload: data,
        });
    }

    refreshPrice(item: PriceData, responseItem: DiscountResponseItem) {
        const conditions = [
            {
                condition: responseItem.basispriceisset,
                selector: '[data-tk-price-basic]',
                className: undefined,
            },
            {
                condition: responseItem.servicepriceisset,
                selector: '[data-tk-price-service]',
                className: this.isServiceClassName,
            },
            {
                condition: responseItem.promopriceisset,
                selector: '[data-tk-price-promotion]',
                className: this.isPromotionClassName,
            },
        ];
        const { selector, className } = conditions.find(({ condition }) => condition) || {};
        if (!selector) return;
        const { html, basicPrice, servicePrice } = responseItem;
        if (!html) return;
        const htmlString = isBase64(html) ? atob(html) : html;
        const pricesElement = render(htmlString, true);
        conditions.forEach((condition) => {
            const priceElement = pricesElement.querySelector(condition.selector);
            const priceElementWrapper = this.querySelector(condition.selector);
            if (!priceElement || !priceElementWrapper) return;
            priceElementWrapper.innerHTML = priceElement.innerHTML;
        });
        className && this.classList.add(className);

        this.checkSamePrice();

        if (
            !this.discountWrapper
            || !this.discountElement
            || !this.discountAction
            || basicPrice - servicePrice === 0
        ) return;
        this.discountAction.hidden = false;
        const discount = Math.round(((basicPrice - servicePrice) / basicPrice) * 100);
        this.discountElement.textContent = `${discount}%`;
    }

    checkSamePrice():void {
        const basicPriceElement = this.querySelector('[data-tk-price-basic]');
        const servicePriceElement = this.querySelector('[data-tk-price-service]');

        if (!basicPriceElement || !servicePriceElement) return;

        const basicPrice = basicPriceElement.getAttribute('data-tk-price-basic');
        const servicePrice = servicePriceElement.getAttribute('data-tk-price-service');

        if (basicPrice === servicePrice) {
            basicPriceElement.classList.add('tk-price--redundant');
        }
    }

    static getItemsAsArray(data: PriceData[]) {
        return data.map((item) => item.articleId);
    }
}
