import TKFavoriteInfo from '@tk/components/tk.favorite.info';

export default class KWFavoriteInfo extends TKFavoriteInfo {
    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === 'data-tk-quantity') {
            if (!this.anchor) return;
            this.anchor.setAttribute('data-count', newValue);
            this.anchor.classList.toggle(this.emptyClassName, Number(newValue) === 0);
            // eslint-disable-next-line no-script-url
            this.anchor.href = this.href;
        }
    }
}
